<template>
  <v-container fluid v-if="currentStudentLesson">
    <v-row no-gutters justify="center" align="center" class="mt-4">
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <h2>Elev lektions detaljer</h2>
          </v-col>
        </v-row>

        <v-simple-table class="mt-2">
          <tbody class="NoHover">
            <tr>
              <td>Elevs navn:</td>
              <td>{{ currentStudentLesson.studentName }}</td>
            </tr>
            <tr>
              <td>Elevs email:</td>
              <td>{{ currentStudentLesson.studentEmail }}</td>
            </tr>
            <tr v-if="currentStudentLesson.lesson">
              <td>Lektion</td>
              <td>{{ currentStudentLesson.lesson.lessonModule.name }}</td>
            </tr>
            <tr v-if="currentStudentLesson.lesson">
              <td>Kørelærer</td>
              <td>{{ currentStudentLesson.lesson.instructor.name }}</td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>{{ $util.StateFromNumberToText(currentStudentLesson.state) }}</td>
            </tr>
            <tr v-if="currentStudentLesson.comment">
              <td>Kommentar:</td>
              <td>{{ currentStudentLesson.comment }}</td>
            </tr>
            <tr>
              <td>Evaluation:</td>
              <td><v-rating v-model="currentStudentLesson.evaluation" readonly background-color="orange lighten-3" color="orange" large></v-rating></td>
            </tr>
            <tr v-if="currentStudentLesson.lesson">
              <td>Lektions start tidspunkt:</td>
              <td>{{ $util.formatDate(currentStudentLesson.lesson.timestamp, true) }}</td>
            </tr>
            <tr v-if="currentStudentLesson.lesson">
              <td>Lektions slut tidspunkt:</td>
              <td>{{ $util.formatDate(currentStudentLesson.lesson.endTimeStamp, true) }}</td>
            </tr>
            <tr v-if="currentStudentLesson.studentSignatureStamp">
              <td>Elev underskrevet:</td>
              <td>{{ $util.formatDate(currentStudentLesson.studentSignatureStamp, true) }}</td>
            </tr>
            <tr v-if="currentStudentLesson.instructorSignatureStamp">
              <td>Kørelærer underskrevet:</td>
              <td>{{ $util.formatDate(currentStudentLesson.instructorSignatureStamp, true) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row no-gutters class="mt-4" v-if="isStaff">
          <v-col cols="12" align="right">
            <r-btn @click="EditCommentAndEvaluation" :block="$vuetify.breakpoint.mobile" :class="$vuetify.breakpoint.mobile ? 'mb-4' : ''">
              <v-icon left>mdi-pencil</v-icon>
              Sæt evaluation
            </r-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <give-comment-and-evaluation @update-student-lesson="GiveEvaluation" ref="GiveCommentAndEvaluationRef"></give-comment-and-evaluation>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import { mapGetters } from 'vuex';
import GiveCommentAndEvaluation from '@/components/GiveCommentAndEvaluation.vue';
import { DateTime } from 'luxon';

export default {
  components: { GiveCommentAndEvaluation },
  name: 'Student-Lesson-Details',
  data: () => ({
    currentStudentLesson: {},
  }),
  props: {
    StudentId: String,
  },
  watch: {
    StudentId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getStudentLesson();
        }
      },
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'isStaff', 'user', 'darkMode']),
  },
  methods: {
    async getStudentLesson() {
      this.currentStudentLesson = await apiService.getStudentLessonsById(this.StudentId);
    },
    EditCommentAndEvaluation() {
      this.$refs.GiveCommentAndEvaluationRef.open(this.currentStudentLesson);
    },
    async GiveEvaluation(value) {
      if (value.instructorSignatureStamp == null) {
        value.instructorSignatureStamp = DateTime.now().toISO();
      }

      await apiService.updateStudentLesson(value).finally(() => {
        this.getStudentLesson();
      });
    },
  },
  created() {},
};
</script>

