<template>
  <v-dialog v-model="show" max-width="650" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <v-card v-if="currentStudentLesson">
      <v-row no-gutters class="">
        <v-card-title>Ændre evaluering</v-card-title>
        <r-btn v-if="$vuetify.breakpoint.mobile" depressed class="ml-auto mr-4 mt-4" icon @click="close">
          <v-icon dark> mdi-close-thick </v-icon>
        </r-btn>
      </v-row>
      <v-card-text>
        <v-row no-gutters justify="center" align="center">
          <v-col cols="2"> Evaluering </v-col>
          <v-col cols="10">
            <v-rating v-model="currentStudentLesson.evaluation" background-color="orange lighten-3" color="orange" large></v-rating>
          </v-col>
          <v-col cols="2" class="my-2"> Kommentar </v-col>
          <v-col cols="10" class="my-2">
            <v-textarea v-model="currentStudentLesson.comment" outlined hide-details="auto" dense></v-textarea>
          </v-col>
          <v-col cols="2" class="mt-2"> Status </v-col>
          <v-col cols="10" class="mt-2">
            <v-select
              :items="$util.StateTypes()"
              outlined
              v-model="currentStudentLesson.state"
              hide-details
              item-value="id"
              item-text="text"
              label="Status"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <r-btn class="mr-4 mb-4" :block="$vuetify.breakpoint.mobile" @click="show = false">Luk</r-btn>
        <r-btn class="mr-4 mb-4" creation :block="$vuetify.breakpoint.mobile" @click="saveChanges" :loading="isSaving"> Gem evaluering </r-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from 'lodash';
export default {
  name: 'StudentLessonEvaluation',
  data: () => ({
    show: false,
    isValid: false,
    currentStudentLesson: null,
    isSaving: false,
  }),
  methods: {
    saveChanges() {
      this.isSaving = true;
      this.$emit('update-student-lesson', this.currentStudentLesson);
      this.isSaving = false;
      this.show = false;
    },
    open(value) {
      this.isSaving = false;
      this.currentStudentLesson = _.cloneDeep(value);
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>
